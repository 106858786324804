import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const showToast = (vm, title, text) => {
  vm.$toast(
    {
      component: ToastificationContent,
      props: {
        title,
        icon: 'BellIcon',
        text,
        variant: 'warning',
      },
    },
    {
      position: 'top-center',
    },
  )
}

export const showAlert = (vm, icon, title, text) => {
  vm.$swal({
    icon,
    title,
    text,
    customClass: {
      confirmButton: `btn btn-${icon}`,
    },
  })
}

import store from '@/store'
import channelStoreModule from '../../channelStoreModule'
import commonStoreModule from '../../commonStoreModule'
import merchantStoreModule from '../../merchantStoreModule'

export default function transactionsJs() {
  const CHANNEL_STORE_MODULE_NAME = 'channel'
  const COMMON_STORE_MODULE_NAME = 'common'
  const MERCHANT_STORE_MODULE_NAME = 'merchant'

  // Register module
  if (!store.hasModule(CHANNEL_STORE_MODULE_NAME)) store.registerModule(CHANNEL_STORE_MODULE_NAME, channelStoreModule)
  if (!store.hasModule(COMMON_STORE_MODULE_NAME)) store.registerModule(COMMON_STORE_MODULE_NAME, commonStoreModule)
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)

  const fetchChannelFilters = (success, fail) => {
    store
      .dispatch('channel/fetchChannelFilters')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchChannels = (success, fail) => {
    store
      .dispatch('channel/fetchChannels')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const saveChannel = (channel, success, fail) => {
    store
      .dispatch('channel/saveChannel', channel)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchChannelElements = (success, fail) => {
    store
      .dispatch('channel/fetchChannelElements')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const saveChannelElement = (element, success, fail) => {
    store
      .dispatch('channel/saveChannelElement', element)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchChannelList = (success, fail) => {
    store
      .dispatch('channel/fetchChannels')
      .then(response => {
        const { code, msg, data } = response.data
        if (code === '10000') {
          const methods = []
          data.forEach(item => {
            const method = { label: item.cname, value: item.cid }
            methods.push(method)
          })

          const result = {}
          result.methods = methods
          // result.currencies = currencies
          success(result)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchants = (success, fail) => {
    store
      .dispatch('channel/fetchMerchants')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          const merchants = []
          data.forEach(item => {
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })

          success(merchants)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const fetchMerchantApps = (vm, merchantNo, callback) => {
    store
      .dispatch('channel/fetchMerchantsApps', merchantNo)
      .then(response => {
        const { code, data } = response.data

        const apps = [{ label: 'All', value: '' }]
        if (code === '10000') {
          data.forEach(item => {
            const app = { label: item.app_name, value: item.app_id }
            apps.push(app)
          })
        }
        callback(apps)
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Merchant APPs', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchChannelFees = (cid, success, fail) => {
    store
      .dispatch('channel/fetchChannelFees', cid)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const updateChannelFee = (params, success, fail) => {
    store
      .dispatch('channel/updateChannelFee', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const deleteChannelFee = (params, success, fail) => {
    store
      .dispatch('channel/deleteChannelFee', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const toggleMonitoring = (params, success, fail) => {
    store
      .dispatch('channel/toggleMonitoring', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const doBackupChannel = (params, success, fail) => {
    store
      .dispatch('channel/backupChannel', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const switchChannel = (params, success, fail) => {
    store
      .dispatch('channel/switchChannel', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const exportChannelFees = (success, fail) => {
    store
      .dispatch('channel/exportChannelFees')
      .then(response => {
        success(response)
      }).catch(() => {
        fail('error')
      })
  }

  const fetchChannelGroups = (success, fail) => {
    store
      .dispatch('channel/fetchChannelGroups')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const createChannelGroup = (params, success, fail) => {
    store
      .dispatch('channel/createChannelGroup', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const updateChannelGroup = (params, success, fail) => {
    store
      .dispatch('channel/updateChannelGroup', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const removeChannelGroup = (params, success, fail) => {
    store
      .dispatch('channel/removeChannelGroup', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchChannelGroupWeights = (groupId, success, fail) => {
    store
      .dispatch('channel/fetchChannelGroupWeights', groupId)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const createChannelWeight = (params, success, fail) => {
    store
      .dispatch('channel/createChannelWeight', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const updateChannelWeight = (params, success, fail) => {
    store
      .dispatch('channel/updateChannelWeight', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const removeChannelWeight = (params, success, fail) => {
    store
      .dispatch('channel/removeChannelWeight', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const channelOnline = (cid, success, fail) => {
    store
      .dispatch('channel/channelOnline', cid)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const channelOffline = (cid, success, fail) => {
    store
      .dispatch('channel/channelOffline', cid)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchCommonOptions = (success, fail) => {
    store
      .dispatch('common/fetchCommonOptions')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          store.commit('common/SET_CURRENCIES', data.currencies)
          store.commit('common/SET_COUNTRIES', data.countries)
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const onlineMerchantChannel = (params, success, fail) => {
    store
      .dispatch('channel/onlineMerchantChannel', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const offlineMerchantChannel = (params, success, fail) => {
    store
      .dispatch('channel/offlineMerchantChannel', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchMerchantAppsNew = (merchantNo, success, fail) => {
    store
      .dispatch('merchant/fetchDropDownAppsMerchant', merchantNo)
      .then(response => {
        const { code, data } = response.data

        const apps = []
        if (code === '10000') {
          data.forEach(item => {
            const app = { label: item.app_name, value: item.app_id }
            apps.push(app)
          })
        }
        success(apps)
      })
      .catch(() => { fail('error') })
  }

  return {
    fetchChannelFilters,
    fetchChannels,
    saveChannel,
    fetchChannelElements,
    saveChannelElement,
    fetchChannelList,
    fetchMerchants,
    fetchMerchantApps,
    fetchChannelFees,
    updateChannelFee,
    deleteChannelFee,
    toggleMonitoring,
    doBackupChannel,
    switchChannel,
    exportChannelFees,
    fetchChannelGroups,
    createChannelGroup,
    updateChannelGroup,
    removeChannelGroup,
    fetchChannelGroupWeights,
    createChannelWeight,
    updateChannelWeight,
    removeChannelWeight,
    channelOnline,
    channelOffline,
    fetchCommonOptions,
    onlineMerchantChannel,
    offlineMerchantChannel,
    fetchMerchantAppsNew,
  }
}

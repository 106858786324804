import axios from '@axios'

export default {
  namespaced: true,
  state: {
    currencies: [],
    countries: [],
  },
  getters: {},
  mutations: {
    SET_CURRENCIES(state, currencies) {
      state.currencies = currencies
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries
    },
  },
  actions: {

    // 获取Daily数据
    fetchIndustryOptions() {
      return new Promise((resolve, reject) => {
        axios.post('/common/industry')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取同盾策略组
    fetchTongdunStrategies() {
      return new Promise((resolve, reject) => {
        axios.post('/common/td-apps')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取通用选择框数据
    fetchCommonOptions() {
      return new Promise((resolve, reject) => {
        axios.post('/common/options')
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },

    // 获取国家数据
    fetchCountryList() {
      return new Promise((resolve, reject) => {
        axios.post('/country-list')
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },

    // 发送短信验证码
    sendVerificationCode(ctx, mobile) {
      return new Promise((resolve, reject) => {
        axios.post(`/send-verification?mobile=${mobile}`)
          .then(response => { resolve(response) })
          .catch(error => { reject(error) })
      })
    },

    // End
  },
}

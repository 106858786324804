<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Channel Router Switch
        </h5>
      </b-card-header>
      <b-card-body class="mt-2 ml-2 mr-2">
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group
              label-for="original-channel"
            >
              <v-select
                id="original-channel"
                v-model="adjustChannel"
                :options="originalChannelOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-button
          variant="info"
          @click="channelAdjust(true)"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Online</span>
        </b-button>
        <b-button
          class="ml-2"
          variant="warning"
          @click="channelAdjust(false)"
        >
          <feather-icon
            icon="MinusIcon"
            class="mr-50"
          />
          <span class="align-middle">Offline</span>
        </b-button>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Merchant APP Selection
        </h5>
      </b-card-header>
      <b-card-body class="mt-2 ml-2 mr-2">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group
              label="Merchants"
              label-for="online-merchants"
            >
              <v-select
                id="online-merchants"
                v-model="onlineMerchant"
                :options="merchantOptions"
                :reduce="val => val.value"
                :clearable="true"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label="APPs"
              label-for="online-apps"
            >
              <v-select
                id="online-apps"
                v-model="onlineApp"
                :options="appOptions"
                :reduce="val => val.value"
                :clearable="true"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-button
          variant="info"
          @click="toggleMerchantChannel(true)"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Online</span>
        </b-button>
        <b-button
          class="ml-2"
          variant="warning"
          @click="toggleMerchantChannel(false)"
        >
          <feather-icon
            icon="MinusIcon"
            class="mr-50"
          />
          <span class="align-middle">Offline</span>
        </b-button>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Merchant Channel Switch
        </h5>
      </b-card-header>
      <b-card-body>
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Submit"
          back-button-text="Previous"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <!-- Original Channel -->
          <tab-content title="Channel From" :before-change="validationChannelFrom">
            <b-row>
              <b-col md="12">

                <!-- Use Channel Router -->
                <b-form-group
                  class="mt-2 mb-2"
                  label-for="use-channel-router"
                >
                  <b-form-checkbox
                    id="use-channel-router"
                    v-model="isOriginalUseRouter"
                  >
                    Use Channel Router
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group
                  label="Channels"
                  label-for="original-channel"
                >
                  <v-select
                    id="original-channel"
                    v-model="originalChannel"
                    :options="originalChannelOptions"
                    :reduce="val => val.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>
          <!-- Target Channel -->
          <tab-content title="Channel To" :before-change="validationChannelTarget">
            <b-row>
              <b-col md="12">

                <!-- Use Channel Router -->
                <b-form-group
                  class="mt-2 mb-2"
                  label-for="target-use-channel-router"
                >
                  <b-form-checkbox
                    id="target-use-channel-router"
                    v-model="isTargetUseRouter"
                  >
                    Use Channel Router
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group
                  label="Channels"
                  label-for="target-channel"
                >
                  <v-select
                    id="target-channel"
                    v-model="targetChannel"
                    :options="targetChannelOptions"
                    :reduce="val => val.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>
          <!-- Merchant Information -->
          <tab-content
            title="Merchant Information"
          >
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Merchants"
                  label-for="include-merchants"
                >
                  <v-select
                    id="include-merchants"
                    v-model="includeMerchants"
                    :options="merchantOptions"
                    :reduce="val => val.value"
                    multiple
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow, BCol, BFormGroup, BFormCheckbox, BCard, BCardHeader, BCardBody, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import { showToast, showAlert } from '@/libs/tool'
import useChannelsJs from './channels'

const {
  fetchChannelList,
  fetchMerchants,
  fetchMerchantAppsNew,
  switchChannel,
  fetchChannelGroups,
  channelOnline,
  channelOffline,
  onlineMerchantChannel,
  offlineMerchantChannel,
} = useChannelsJs()

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  data() {
    return {
      merchantOptions: [],
      appOptions: [],
      originalChannelOptions: [],
      targetChannelOptions: [],
      channelOptions: [],
      channelGroupOptions: [],
      includeMerchants: [],
      includeApps: [],
      includeMethods: [],
      excludeMerchants: [],
      targetMerchants: [],
      excludeApps: [],
      excludeMethods: [],
      originalChannel: '',
      targetChannel: '',
      configDetail: {
        upperBound: -1,
        lowerBound: -1,
        numberValue: 0,
        upperLimit: -1,
        lowerLimit: -1,
        strValue: '',
      },
      isOriginalUseRouter: false,
      isTargetUseRouter: false,
      adjustChannel: '',
      onlineMerchant: '',
      onlineApp: '',

      required,
    }
  },
  watch: {
    isOriginalUseRouter(val) {
      this.originalChannel = ''
      if (val) {
        this.originalChannelOptions = this.channelGroupOptions
      } else {
        this.originalChannelOptions = this.channelOptions
      }
    },
    isTargetUseRouter(val) {
      this.targetChannel = ''
      if (val) {
        this.targetChannelOptions = this.channelGroupOptions
      } else {
        this.targetChannelOptions = this.channelOptions
      }
    },
    onlineMerchant(val) {
      fetchMerchantAppsNew(
        val,
        apps => {
          this.appOptions = apps
        },
        fail => {
          this.loading = false
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
  created() {
    this.loadOptions()
  },
  methods: {
    validationChannelFrom() {
      return this.originalChannel && this.originalChannel !== ''
    },
    validationChannelTarget() {
      return this.targetChannel && this.targetChannel !== ''
    },
    loadOptions() {
      fetchChannelList(
        elements => {
          this.channelOptions = elements.methods
          this.originalChannelOptions = this.channelOptions
          this.targetChannelOptions = this.channelOptions
        },
        fail => {
          showToast(this, 'Warning', `Cannot get Methods with ${fail}`)
        },
      )
      fetchChannelGroups(
        channelGroups => {
          const groups = []
          channelGroups.forEach(item => {
            const group = { label: item.group_name, value: item.group_id }
            groups.push(group)
          })
          this.channelGroupOptions = groups
        },
        fail => {
          showToast(this, 'Warning', `Fetch Channel Groups fail with ${fail}.`)
        },
      )
      fetchMerchants(
        merchants => {
          this.merchantOptions = merchants
        },
        fail => {
          showToast(this, 'Warning', `Cannot get merchants with ${fail}`)
        },
      )
    },
    formSubmitted() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const params = {
          source_channel: `${this.isOriginalUseRouter ? 'ROUTER:' : ''}${this.originalChannel}`,
          target_channel: `${this.isTargetUseRouter ? 'ROUTER:' : ''}${this.targetChannel}`,
          merchants: this.includeMerchants.join(','),
          apps: this.includeApps.join(','),
        }
        if (result.value) {
          switchChannel(
            params,
            success => {
              showToast(this, 'Success', `switch success: ${success}.`)
            },
            fail => {
              showToast(this, 'Warning', `switch fail with ${fail}.`)
            },
          )
        }
      })
    },
    channelAdjust(val) {
      if (!this.adjustChannel) {
        showToast(this, 'Warning', 'No Channel Selected!')
        return
      }
      this.$swal({
        title: 'Are you sure?',
        text: `${val ? 'Online' : 'Offline'} ${this.adjustChannel.label} ???`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (val) {
            this.doOnline()
          } else {
            this.doOffline()
          }
        }
      })
    },
    doOnline() {
      channelOnline(
        this.adjustChannel.value,
        success => {
          showAlert(this, 'success', 'Success!', `${success} updated!`)
        },
        fail => {
          this.loading = false
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    doOffline() {
      channelOffline(
        this.adjustChannel.value,
        success => {
          showAlert(this, 'success', 'Success!', `${success} updated!`)
        },
        fail => {
          this.loading = false
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    toggleMerchantChannel(val) {
      if (!this.adjustChannel) {
        showToast(this, 'Warning', 'No Channel Selected!')
        return
      }
      this.$swal({
        title: 'Are you sure?',
        text: `Online ${this.adjustChannel.label} ???`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (val) {
            this.doMerchantOnline()
          } else {
            this.doMerchantOffline()
          }
        }
      })
    },
    doMerchantOnline() {
      const params = {
        cid: this.adjustChannel.value,
        merchant_no: this.onlineMerchant,
        app_id: this.onlineApp,
      }
      onlineMerchantChannel(
        params,
        success => {
          showAlert(this, 'success', 'Success!', `${success} updated!`)
        },
        fail => {
          this.loading = false
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
    doMerchantOffline() {
      const params = {
        cid: this.adjustChannel.value,
        merchant_no: this.onlineMerchant,
        app_id: this.onlineApp,
      }
      offlineMerchantChannel(
        params,
        success => {
          showAlert(this, 'success', 'Success!', `${success} updated!`)
        },
        fail => {
          this.loading = false
          showAlert(this, 'warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
</style>
